import * as React from "react"

import {Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from '../components/Seo'

import { getAndSetLang, isLangEng, isLangIt } from "../utils/language" 
import "../utils/language"


const Discussione = () => {
	const lll="https://didattica.polito.it/pls/portal30/sviluppo.bbb_corsi.waitingRoom?id=9434&p_tipo=COMM_LAUREA"

    let lang = getAndSetLang("it")
    
    let content
    let title

    if( isLangIt() ){
        title="Discusione"
        content =
        <>
            <p>La discussione, per fortuna, sarà trasmessa online, cosicché chiunque nel mondo possa godersi la mia mia tesi.
                Ovviamente la discussione sarà in inglese... Mi dispiace che molti non potranno seguirla,
                ma tanto non si sarebbe capito niente comunque!!! 🤣
            </p>
            <p>
                La discussione è fissata per il <b>20 Ottobre 2022</b> alle <b>9:00 AM</b>. Ovvero di mattina!
            </p>
            <p>
                Si può tranquillamente accede anche prima della data. Anzi, c'è scritta la data e l'orario precisi.
            </p>
            <p>
                <b>NOTA: </b>  La proclamazione e la discussione sono due eventi diversi, in due giorni diversi. 
                Per avere informazioni sulla proclamazione, vai nella sezione apposita.
            </p>
        </>
    }
    if( isLangEng() ){
        title="Welcome"
        content = 
        <>
            <p>The defence, luckily, will be streamed online, so that anyone in the world could enjoy my thesis.
                Obviously the discussion will be in English, but if you are reading this, there no problem for you 😎
                But I think many people won't understand anyway!!! 🤣
            </p>
            <p>
                The defence is scheduled for <b>October 20<sup>th</sup> 2022</b> at <b>9:00 AM</b>.
            </p>
            <p>
                You can click on the link before this date. It is actually recommended to do so in order to enure the date is correct.
            </p>  
            <p>
                <b>NOTE: </b>  The actual graduation and the thesis defence are tow different events, in two different dates.
                For more information about the graduation, go to the specific section.
            </p>
        </>
    }

	return(
		<Layout pageTitle={title} lang={lang}>
			{content}
			<Link to={lll}>
				{lll}
			</Link>
		</Layout>
	)
}

export const Head = () => <Seo title="Discussione" />


export default Discussione